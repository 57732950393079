import { createAsyncThunk } from '@reduxjs/toolkit';
import { IMetrics } from '../types/metrics';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const fetchMetrics = createAsyncThunk<
  IMetrics,
  void,
  ThunkConfig<string>
>('metrics/metrics', async (dates, thunkApi) => {
  const { extra, rejectWithValue, getState } = thunkApi;

  const {
    metrics: { forRequest },
  } = getState();

  try {
    const response = await extra.api.post<IMetrics>(urls.metrics.postMetrics, {
      ...forRequest,
    });
    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('error load metrics');
  }
});
