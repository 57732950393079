import {
  configureStore,
  Reducer,
  ReducersMapObject,
  ThunkDispatch,
  UnknownAction,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import { createReducerManager } from './reducerManager';
import { StateSchema, ThunkExtraArg, TStore } from './stateSchema';
import { customerReducer } from '@/entities/Customer';
import { decisionsReducer } from '@/entities/Decisions';
import { marketingLinkReducer } from '@/entities/MarketingLink';
import { messageReducer } from '@/entities/Message';
import { metricsReducer } from '@/entities/Metrics';
import { partnerReducer } from '@/entities/Partner';
import { ProductReducer } from '@/entities/Product';
import { SCVFormatReducer } from '@/entities/SCVFormat';
import { transactionReducer } from '@/entities/Transaction';
import { userReducer } from '@/entities/User';
import app from '@/shared/api/api';
import { rtkApi } from '@/shared/api/rtkApi';

export function createReduxStore(
  initialState?: DeepPartial<StateSchema>,
  asyncReducers?: ReducersMapObject<StateSchema>,
) {
  const isLocal =
    process.env.APP_ENV === 'local' || process.env.APP_ENV === 'dev';

  const rootReducer: ReducersMapObject<StateSchema> = {
    ...asyncReducers,
    SCVFormat: SCVFormatReducer,
    customers: customerReducer,
    decisions: decisionsReducer,
    marketingLinks: marketingLinkReducer,
    messages: messageReducer,
    metrics: metricsReducer,
    partners: partnerReducer,
    product: ProductReducer,
    transaction: transactionReducer,
    user: userReducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
  };

  const reducerManager = createReducerManager(rootReducer);
  const persistedReducer = persistReducer(
    { key: 'root', storage: localStorage, whitelist: ['usersTable', 'user'] },
    // @ts-ignore
    reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
  );
  const extraArg: ThunkExtraArg = { api: app };

  const store = configureStore({
    devTools: isLocal,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: !isLocal,
        serializableCheck: !isLocal,
        thunk: { extraArgument: extraArg },
      }).concat(rtkApi.middleware),
    preloadedState: initialState,
    reducer: persistedReducer,
  }) as TStore;

  store.reducerManager = reducerManager;
  const persist = persistStore(store);

  return { persist, store };
}

export type AppDispatch = ThunkDispatch<
  StateSchema,
  ThunkExtraArg,
  UnknownAction
>;
