import axios from 'axios';
import { SnackbarTypeT } from '@/shared/ui/Snackbars/Snackbars';

export const logoutInApp = (
  message?: string,
  typeMessage: SnackbarTypeT = 'error',
) => {
  localStorage.clear();
  sessionStorage.clear();
  sessionStorage.removeItem('persist:root');
  localStorage.removeItem('persist:root');
  // message && showSnackbar(message, typeMessage);
  window.location.href = '/';
};

export const logoutAxios = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const customError = error.response as IResponse<IErrorMessage>;
    console.log(customError.data.message);
    logoutInApp('The session is outdated. Log in again.');
  } else console.log('error in axios', error);
};
