import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginByUsername } from '../services/loginByUsername';
import { logoutQuery } from '../services/logoutQuery';
import { IUserSchema } from '../types/user';
import { logoutInApp } from '@/shared/lib/api/logoutAxios';

const initialState: IUserSchema = {
  data: {
    email: '',
    firstName: '',
    id: '',
    role: 'TEST',
  },
  isLoadingUser: false,
  showLogoutToast: false,
};

export const userSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(loginByUsername.pending, state => {
        state.isErrorUser = undefined;
        state.isLoadingUser = true;
      })
      .addCase(loginByUsername.fulfilled, (state, { payload }) => {
        state.isLoadingUser = false;
        const { accessToken, ...otherProps } = payload;
        localStorage.setItem('USER_AUTH_TOKEN', payload.accessToken);
        state.data = otherProps;
      })
      .addCase(loginByUsername.rejected, (state, { payload }) => {
        state.isLoadingUser = false;
        state.isErrorUser = payload;
      })
      .addCase(logoutQuery.pending, state => {
        state.isErrorUser = undefined;
        state.isLoadingUser = true;
      })
      .addCase(logoutQuery.fulfilled, state => {
        state.showLogoutToast = true;
        state.isLoadingUser = false;
        state.data = { email: '', firstName: '', id: '', role: 'TEST' };
        logoutInApp('You are logged out of the system.', 'success');
      })
      .addCase(logoutQuery.rejected, (state, { payload }) => {
        state.isLoadingUser = false;
        state.isErrorUser = payload;
      });
  },
  initialState,
  name: 'user',
  reducers: {
    setIsLoadingUser: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUser = action.payload;
    },
    setShowLogoutToast: (state, action: PayloadAction<boolean>) => {
      state.showLogoutToast = action.payload;
    },
  },
});

export const { setIsLoadingUser, setShowLogoutToast } = userSlice.actions;
export const { reducer: userReducer } = userSlice;
