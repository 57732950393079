import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchMetrics } from '../services/serviceMetrics';
import { IMetrics, IMetricsForRequest, IMetricsSchema } from '../types/metrics';

const initialState: IMetricsSchema = {
  data: {
    arr: '',
    churn: {
      crypto: '',
      forex: '',
      stocks: '',
      total: '',
    },
    copyTrading: {
      crypto: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
      forex: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
      stocks: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
    },
    mrr: {
      mrr: '',
      new: '',
      react: '',
    },
    screener: {
      crypto: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
      forex: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
      stocks: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
    },
    signals: {
      crypto: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
      forex: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
      stocks: {
        churn: '',
        mrr: {
          mrr: '',
          new: '',
          react: '',
        },
        trialConversion: '',
      },
    },
  },
  forRequest: { dates: null },
  isLoading: false,
};

export const metricsSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchMetrics.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        fetchMetrics.fulfilled,
        (state, action: PayloadAction<IMetrics>) => {
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(fetchMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
  initialState,
  name: 'metrics',
  reducers: {
    setFiltersMetrics: (
      state,
      { payload }: PayloadAction<IMetricsForRequest>,
    ) => {
      state.forRequest = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setMetricsInitialFilters: state => {
      state.forRequest.dates = initialState.forRequest.dates;
      state.data = initialState.data;
    },
  },
});

export const { setIsLoading, setFiltersMetrics, setMetricsInitialFilters } =
  metricsSlice.actions;
export const { reducer: metricsReducer } = metricsSlice;
